
import React from 'react';
import '../style.css';

export const Faq = () => {
    return (
        
    <div>
    <section className="showcase">
      <div className="container">
          <div className="col-12">
          <h1>Pregunta Frecuentes</h1>

          <div className="doubleSpace">
            <h4>Pregunta 1</h4>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam tellus ut lectus lacinia aliquam. Proin tristique tristique nisl, vitae convallis massa euismod at. Donec nisi sapien, tempus ut fermentum pharetra, ullamcorper vel lorem. 
            </p>
            </div>

            <div className="doubleSpace">
            <h4>Pregunta 2</h4>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam tellus ut lectus lacinia aliquam. Proin tristique tristique nisl, vitae convallis massa euismod at. Donec nisi sapien, tempus ut fermentum pharetra, ullamcorper vel lorem. 
            </p>
            </div>

            <div className="doubleSpace">
            <h4>Pregunta 3</h4>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam tellus ut lectus lacinia aliquam. Proin tristique tristique nisl, vitae convallis massa euismod at. Donec nisi sapien, tempus ut fermentum pharetra, ullamcorper vel lorem. 
            </p>
            </div>

            <div className="doubleSpace">
            <h4>Pregunta 4</h4>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam tellus ut lectus lacinia aliquam. Proin tristique tristique nisl, vitae convallis massa euismod at. Donec nisi sapien, tempus ut fermentum pharetra, ullamcorper vel lorem. 
            </p>
            </div>

            <div className="doubleSpace">
            <h4>Pregunta 5</h4>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam tellus ut lectus lacinia aliquam. Proin tristique tristique nisl, vitae convallis massa euismod at. Donec nisi sapien, tempus ut fermentum pharetra, ullamcorper vel lorem. 
            </p>
            </div>







          </div>
        </div>
    </section>
  </div>
    );
  };
  