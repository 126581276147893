

import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import '../style.css';
import { estados } from './estados';
import { paises } from './paises';
import { URI_PATH } from './Paremters';

export const RegistroP1 = ({ userId, onNextStep, tempData, setTempData }) => {

    const municipio_dropdown = useRef(null);
    const generoOptions = ['Mujer', 'Hombre', 'Prefiero no decir'];
    const [municipioDropdownEnabled, setMunicipioDropdownEnabled] = useState(tempData.pais === 'México');

    const [missingField, setMissingField] = useState(null);
    const [selectedGenero, setSelectedGenero] = useState(tempData.genero || '');


    const handleGeneroChange = (e) => {
      setSelectedGenero(e.target.value);
      handleInputChange(e); // Actualiza el valor en tempData
    };

  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setTempData({
        ...tempData,
        [name]: value,
      });
    };


    const isValidEmail = (email) => {
      // Expresión regular para validar un correo electrónico básico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    

    const handleEdadChange = (e) => {
      const { value } = e.target;
      // Verifica si el valor es numérico y tiene máximo 2 dígitos
      const numericValue = value.replace(/\D/g, ''); // Elimina no numéricos
      const truncatedValue = numericValue.slice(0, 2); // Limita a 2 dígitos
      setTempData({
        ...tempData,
        edad: truncatedValue,
      });
    };


    const handleCPChange = (e) => {
      const { value } = e.target;
      
      // const numericValue = value.replace(/\D/g, ''); // Elimina no numéricos
      // const truncatedValue = numericValue.slice(0, 12); // Limita a 2 dígitos

      // Permitir letras (A-Z, a-z), números (0-9), y guiones (-)
    const validCP = value.replace(/[^A-Za-z0-9-]/g, '');
    
    // Limitar a 12 caracteres
    const truncatedCP = validCP.slice(0, 12);

      setTempData({
        ...tempData,
        cp: truncatedCP,
      });
    };
  
    

    const verificarCorreo = async (email) => {
      const response = await fetch(URI_PATH + 'verifica_correo.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
    
      const result = await response.text();
      return result ;
    };

    const handleContinueClick =async () => {

      setErrorMensaje('');
      // Verificar que todos los campos estén llenos
      let emptyField = Object.keys(tempData).find((key) => !tempData[key]);
      
      // Validar el correo electrónico
      const invalidEmail = tempData.email && !isValidEmail(tempData.email);
      
      // Verificar si el país es México
      const isMexico = tempData.pais === 'México';
      
      // Validar que el género esté seleccionado
      const isGeneroSelected = generoOptions.includes(tempData.genero);
      
      // Validar que los campos de correo electrónico sean iguales
      const emailsMatch = tempData.email === tempData.confirmEmail;
      
      // Concatenar el mensaje de campo faltante si el correo electrónico no coincide
      const errorMessage = emailsMatch ? '' : 'EMAIL y CONFIRMA EMAIL deben ser iguales.';
      
      if (isMexico) {
        emptyField = Object.keys(tempData).find(
          (key) => key !== 'numeroInterior' && key !== 'idUrl' && !tempData[key]
        );
      } else {
        emptyField = Object.keys(tempData).find(
          (key) => key !== 'numeroInterior' && key !== 'idUrl' && key !== 'estado' && key !== 'municipio' && !tempData[key]
        );
      }
      
      // Si el país es México, entonces los campos estado y municipio son obligatorios
      if (emptyField || invalidEmail || !isGeneroSelected || !emailsMatch) {
        setMissingField(emptyField || 'email' || 'genero' || 'confirmEmail');

        if(emptyField == 'cp'){
          setMissingField('C.P.')
        }

        if(!emailsMatch ){
          setMissingField('emailsMatch')
        }

      } else {



        const emailRegistrado = await verificarCorreo(tempData.email);

        console.log(emailRegistrado)

        if (emailRegistrado === '"true"') {
            // console.log("no paso")
            setMissingField('registred');
            return;
        }else{
          // console.log(" paso")
            onNextStep();
        }

        // Si el correo no está registrado, continuar al siguiente paso
        
      }



    };
    
   
    

    const [identificacionImg, setIdentificacionImg] = useState(null);
    const [errorMensaje, setErrorMensaje] = useState('');



    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/jpeg, image/png, image/gif,',
      onDrop: (acceptedFiles) => {
        const selectedImage = acceptedFiles[0];

        setErrorMensaje('');
        setMissingField('')
   
        const validFiles = acceptedFiles.filter(file =>
          file.type === 'image/jpeg' || file.type === 'image/jpg' || file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.type === 'image/png' 
        );    
        
        if (validFiles.length === 0) {          
          setErrorMensaje('Error: La Identificación solo se permite archivos JPG/PNG');
          return;
        }

        const validFilesSize = validFiles.filter(file =>
          file.size <= 10 * 1024 * 1024
        );
    
        // Si no hay archivos válidos (que cumplan ambos criterios), mostrar un mensaje de error y retornar
        if (validFilesSize.length === 0) {
          setErrorMensaje('Error: La Identificación deben ser menor o iguales a 10 MB');
          return;
        }


    
        if (selectedImage) {          
          setIdentificacionImg(selectedImage);
          handleInputChange({
            target: {
              name: 'identificacionOficial',
              value: selectedImage.name,
            },
          });    
          
          setTempData((prevData) => ({
            ...prevData,
            identificacionImagen: selectedImage,
          }));
    
          //console.log('Imagen seleccionada:', selectedImage);
        }
      },
    });
    
    


    const isValidPhoneNumber = (phoneNumber) => {
      // Expresión regular para validar números y guion medio
      const phoneRegex = /^[0-9-]+$/;
      return phoneRegex.test(phoneNumber) && phoneNumber.length <= 15;
    };


    const handleTelefonoChange = (e) => {
      const { value } = e.target;
      // Verifica si el valor contiene solo números y guion medio, y tiene máximo 15 caracteres
      const sanitizedValue = value.replace(/[^0-9-]/g, ''); // Elimina no numéricos ni guiones medios
      const truncatedValue = sanitizedValue.slice(0, 12);
      setTempData({
        ...tempData,
        telefono: truncatedValue,
      });
    };



    const handleInputChangeNombre = (e) => {
      const { name, value } = e.target;
      
      // Expresión regular para validar letras y espacios
      const onlyLettersRegex = /^[A-Za-z\s]+$/;
      
      // Verificar si el valor ingresado contiene solo letras y espacios
      if (onlyLettersRegex.test(value) || value === '') {
        setTempData({
          ...tempData,
          [name]: value,
        });
      }
    };
    
    


   



    
  
  return (
    <div className='fu-h'>
      <section className="showcase">
        <div className="container">
          <div className="row g-0 text-center d-flex justify-content-xl-center item-sevicio acerca-autor">
            <div className="col-12 text-center my-auto order-lg-1">
              <img src="./assets/paso.jpg" width={337} height={70} alt="Paso 1" />
            </div>

            <div className="col-12 col-xl-8 text-start my-auto order-lg-1 showcase-text align-items-center ">
             
             
             
              <div className="body-input h2">
                <h2 className="text-start">ACERCA DEL PARTICIPANTE</h2>
              </div>

              <div className="body-input p">
                <p className="text-start">Campos obligatorios *</p>
              </div>

              {/* Campos de entrada */}
              <div className="body-input ">
                <label className="form-label input-lbl">NOMBRE *</label>
                <input
                  type="text"
                  name="nombre"
                  onChange={handleInputChangeNombre}
                  value={tempData.nombre}
                />
              </div>

              <div className="body-input">
                <label className="form-label input-lbl">APELLIDOS *</label>
                <input
                  type="text"
                  name="apellidos"
                  onChange={handleInputChangeNombre}
                  value={tempData.apellidos}
                />
              </div>

              <div className="body-input">
                <label className="form-label input-lbl">EMAIL *</label>
                <input
                  type="text"
                  name="email"
                  onChange={handleInputChange}
                  value={tempData.email}
                />
              </div>

              <div className="body-input">
                <label className="form-label input-lbl">CONFIRMA EMAIL *</label>
                <input
                  type="text"
                  name="confirmEmail"
                  onChange={handleInputChange}
                  value={tempData.confirmEmail}
                />
              </div>
              
              <div className="body-input">
                <label className="form-label input-lbl">EDAD *</label>
                <input
                  type="text"
                  name="edad"
                  onChange={handleEdadChange}
                  value={tempData.edad}
                />
              </div>
              
              <div className="body-input">
                <label className="form-label input-lbl">GÉNERO *</label>
                <select
                  value={selectedGenero}
                  name="genero"
                  onChange={handleGeneroChange}
                >
                  <option value="" disabled>
                    Selecciona un género
                  </option>
                  {generoOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="linea-horizontal"></div>


            </div>
            <div className="col-12 col-xl-8 text-start my-auto order-lg-1 showcase-text align-items-center">
          
         

            <div className="body-input">
              <label className="form-label input-lbl">PAÍS *</label>
              <select
                value={tempData.pais}  // Establecer el valor predeterminado como 'MX' para México
                name="pais"
                onChange={(e) => {
                  setTempData({
                    ...tempData,
                    pais: e.target.value,
                  });

                  console.log(e.target.value)
                  if (e.target.value === 'México') {
                    setMunicipioDropdownEnabled(true);
                  } else {
                    setMunicipioDropdownEnabled(false);
                  }



                }}
              >
               
                <option value="" disabled>
                  Selecciona un país
                </option>
                {paises.map((pais) => (
                  <option key={pais.nombre} value={pais.nombre}>
                    {pais.nombre}
                  </option>
                ))}
              </select>
            </div>









            <div className="body-input">
                <label className="form-label input-lbl">CALLE *</label>
                <input
                type="text"
                name="calle"
                onChange={handleInputChange}
                value={tempData.calle}
                />
            </div>

            <div className="body-input">
                <label className="form-label input-lbl">NÚMERO EXTERIOR *</label>
                <input
                type="text"
                name="numeroExterior"
                onChange={handleInputChange}
                value={tempData.numeroExterior}
                />
            </div>

            <div className="body-input">
                <label className="form-label input-lbl">NÚMERO INTERIOR </label>
                <input
                type="text"
                name="numeroInterior"
                onChange={handleInputChange}
                value={tempData.numeroInterior}
                />
            </div>

            <div className="body-input">
                <label className="form-label input-lbl">C.P. *</label>
                <input
                type="text"
                name="cp"
                onChange={handleCPChange}
                value={tempData.cp}
                />
            </div>

            <div className="body-input">
                <label className="form-label input-lbl">CIUDAD/MUNICIPIO *</label>
                <input
                
                type="text"
                name="municipio"
                onChange={handleInputChangeNombre}
                value={tempData.municipio}
                />
            </div>




            <div className="body-input">
                <label className="form-label input-lbl">ESTADO *</label>
                               
               
                <select
                    ref={municipio_dropdown}
                    disabled={!municipioDropdownEnabled}

                    value={tempData.estado}
                    name="estado"
                    onChange={(e) => {
                        setTempData({
                            ...tempData,
                            estado: e.target.value, 
                        });
                    }}
                >
                    <option value="" disabled>Selecciona un estado</option>
                    {estados.map((estado) => (
                        <option key={estado.nombre} value={estado.nombre}>
                            {estado.nombre}
                        </option>
                    ))}
                </select>

            </div>



            <div className="body-input">
                <label className="form-label input-lbl">TELÉFONO *</label>
                <input
                  type="text"
                  name="telefono"
                  onChange={handleTelefonoChange}
                  value={tempData.telefono}
                />
              </div>

            <div className="linea-horizontal"></div>
            </div>
         



            <div className="col-12 col-xl-8 text-start my-auto order-lg-1 showcase-text align-items-center">
              <div className="body-input bi-sim">
                <label className="form-label input-lbl">IDENTIFICACIÓN OFICIAL * <br></br>vigente con fotografía y firma.</label>
                <div className="ico-selecccion-img bi-sim" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {identificacionImg ? (
                    <img className='identificacion ' src={URL.createObjectURL(identificacionImg)} alt="Identificación" />
                  ) : (
                    <div  className='ico-selecccion-img bi-sim '>
                        <img className='hand'  src="./assets/save.jpg" width={150} height={150} alt="Selecciona una imagen" />
                        
                    </div>
                  )}
                </div>
              </div>
              <div className="linea-horizontal"></div>
            </div>








            <div className="col-12 col-xl-8 text-start my-auto order-lg-1 showcase-text align-items-center">

              {/* Agregar más campos según sea necesario */}


                {missingField && (
                    <p style={{ color: 'DeepPink' }}>
                      { 
                        missingField === 'email'
                        ? 'Ingresa un correo electrónico válido.'
                        : missingField === 'identificacionImagen'
                        ? `Falta tu Identificación. Por favor, proporcionala.`
                        : missingField === 'emailsMatch'
                        ? `Ambos campos de correo deben coincidir.`

                        : missingField === 'registred'
                        ? `ERROR: El correo ya está registrado.`
                       

                        : `El campo ${missingField} es obligatorio. Por favor, complétalo.`
                      }                      
                    
                    </p>
                  )}


                {errorMensaje && <p style={{ color: 'DeepPink' }}>{errorMensaje}</p>}

            </div>

           



            <div className="col-12 text-center my-auto order-lg-1 showcase-text">
              <button className="btn btn-primary btn-pink "  type="button"
                onClick={handleContinueClick}
              >
                Continuar
              </button>
            </div>



          </div>
        </div>
      </section>
    </div>
  );
};
