export const paises = [
    
    { nombre: 'México', abreviatura: 'MX' },

    { nombre: 'Afganistán', abreviatura: 'AF' },
    { nombre: 'Albania', abreviatura: 'AL' },
    { nombre: 'Alemania', abreviatura: 'DE' },
    { nombre: 'Andorra', abreviatura: 'AD' },
    { nombre: 'Angola', abreviatura: 'AO' },
    { nombre: 'Antigua y Barbuda', abreviatura: 'AG' },
    { nombre: 'Arabia Saudita', abreviatura: 'SA' },
    { nombre: 'Argelia', abreviatura: 'DZ' },
    { nombre: 'Argentina', abreviatura: 'AR' },
    { nombre: 'Armenia', abreviatura: 'AM' },
    { nombre: 'Australia', abreviatura: 'AU' },
    { nombre: 'Austria', abreviatura: 'AT' },
    { nombre: 'Azerbaiyán', abreviatura: 'AZ' },
    { nombre: 'Bahamas', abreviatura: 'BS' },
    { nombre: 'Bahréin', abreviatura: 'BH' },
    { nombre: 'Bangladés', abreviatura: 'BD' },
    { nombre: 'Barbados', abreviatura: 'BB' },
    { nombre: 'Bélgica', abreviatura: 'BE' },
    { nombre: 'Belice', abreviatura: 'BZ' },
    { nombre: 'Benín', abreviatura: 'BJ' },
    { nombre: 'Bielorrusia', abreviatura: 'BY' },
    { nombre: 'Birmania', abreviatura: 'MM' },
    { nombre: 'Bolivia', abreviatura: 'BO' },
    { nombre: 'Bosnia y Herzegovina', abreviatura: 'BA' },
    { nombre: 'Botsuana', abreviatura: 'BW' },
    { nombre: 'Brasil', abreviatura: 'BR' },
    { nombre: 'Brunéi', abreviatura: 'BN' },
    { nombre: 'Bulgaria', abreviatura: 'BG' },
    { nombre: 'Burkina Faso', abreviatura: 'BF' },
    { nombre: 'Burundi', abreviatura: 'BI' },
    { nombre: 'Bután', abreviatura: 'BT' },
    { nombre: 'Cabo Verde', abreviatura: 'CV' },
    { nombre: 'Camboya', abreviatura: 'KH' },
    { nombre: 'Camerún', abreviatura: 'CM' },
    { nombre: 'Canadá', abreviatura: 'CA' },
    { nombre: 'Catar', abreviatura: 'QA' },
    { nombre: 'Chad', abreviatura: 'TD' },
    { nombre: 'Chile', abreviatura: 'CL' },
    { nombre: 'China', abreviatura: 'CN' },
    { nombre: 'Chipre', abreviatura: 'CY' },
    { nombre: 'Colombia', abreviatura: 'CO' },
    { nombre: 'Comoras', abreviatura: 'KM' },
    { nombre: 'Congo', abreviatura: 'CG' },
    { nombre: 'Corea del Norte', abreviatura: 'KP' },
    { nombre: 'Corea del Sur', abreviatura: 'KR' },
    { nombre: 'Costa de Marfil', abreviatura: 'CI' },
    { nombre: 'Costa Rica', abreviatura: 'CR' },
    { nombre: 'Croacia', abreviatura: 'HR' },
    { nombre: 'Cuba', abreviatura: 'CU' },
    { nombre: 'Dinamarca', abreviatura: 'DK' },
    { nombre: 'Dominica', abreviatura: 'DM' },
    { nombre: 'Ecuador', abreviatura: 'EC' },
    { nombre: 'Egipto', abreviatura: 'EG' },
    { nombre: 'El Salvador', abreviatura: 'SV' },
    { nombre: 'Emiratos Árabes Unidos', abreviatura: 'AE' },
    { nombre: 'Eritrea', abreviatura: 'ER' },
    { nombre: 'Eslovaquia', abreviatura: 'SK' },
    { nombre: 'Eslovenia', abreviatura: 'SI' },
    { nombre: 'España', abreviatura: 'ES' },
    { nombre: 'Estados Unidos', abreviatura: 'US' },
    { nombre: 'Estonia', abreviatura: 'EE' },
    { nombre: 'Etiopía', abreviatura: 'ET' },
    { nombre: 'Filipinas', abreviatura: 'PH' },
    { nombre: 'Finlandia', abreviatura: 'FI' },
    { nombre: 'Fiyi', abreviatura: 'FJ' },
    { nombre: 'Francia', abreviatura: 'FR' },
    { nombre: 'Gabón', abreviatura: 'GA' },
    { nombre: 'Gambia', abreviatura: 'GM' },
    { nombre: 'Georgia', abreviatura: 'GE' },
    { nombre: 'Ghana', abreviatura: 'GH' },
    { nombre: 'Granada', abreviatura: 'GD' },
    { nombre: 'Grecia', abreviatura: 'GR' },
    { nombre: 'Guatemala', abreviatura: 'GT' },
    { nombre: 'Guinea', abreviatura: 'GN' },
    { nombre: 'Guinea-Bisáu', abreviatura: 'GW' },
    { nombre: 'Guinea Ecuatorial', abreviatura: 'GQ' },
    { nombre: 'Guyana', abreviatura: 'GY' },
    { nombre: 'Haití', abreviatura: 'HT' },
    { nombre: 'Honduras', abreviatura: 'HN' },
    { nombre: 'Hungría', abreviatura: 'HU' },
    { nombre: 'India', abreviatura: 'IN' },
    { nombre: 'Indonesia', abreviatura: 'ID' },
    { nombre: 'Irak', abreviatura: 'IQ' },
    { nombre: 'Irán', abreviatura: 'IR' },
    { nombre: 'Irlanda', abreviatura: 'IE' },
    { nombre: 'Islandia', abreviatura: 'IS' },
    { nombre: 'Islas Marshall', abreviatura: 'MH' },
    { nombre: 'Islas Salomón', abreviatura: 'SB' },
    { nombre: 'Israel', abreviatura: 'IL' },
    { nombre: 'Italia', abreviatura: 'IT' },
    { nombre: 'Jamaica', abreviatura: 'JM' },
    { nombre: 'Japón', abreviatura: 'JP' },
    { nombre: 'Jordania', abreviatura: 'JO' },
    { nombre: 'Kazajistán', abreviatura: 'KZ' },
    { nombre: 'Kenia', abreviatura: 'KE' },
    { nombre: 'Kirguistán', abreviatura: 'KG' },
    { nombre: 'Kiribati', abreviatura: 'KI' },
    { nombre: 'Kuwait', abreviatura: 'KW' },
    { nombre: 'Laos', abreviatura: 'LA' },
    { nombre: 'Lesoto', abreviatura: 'LS' },
    { nombre: 'Letonia', abreviatura: 'LV' },
    { nombre: 'Líbano', abreviatura: 'LB' },
    { nombre: 'Liberia', abreviatura: 'LR' },
    { nombre: 'Libia', abreviatura: 'LY' },
    { nombre: 'Liechtenstein', abreviatura: 'LI' },
    { nombre: 'Lituania', abreviatura: 'LT' },
    { nombre: 'Luxemburgo', abreviatura: 'LU' },
    { nombre: 'Madagascar', abreviatura: 'MG' },
    { nombre: 'Malasia', abreviatura: 'MY' },
    { nombre: 'Malaui', abreviatura: 'MW' },
    { nombre: 'Maldivas', abreviatura: 'MV' },
    { nombre: 'Malí', abreviatura: 'ML' },
    { nombre: 'Malta', abreviatura: 'MT' },
    { nombre: 'Marruecos', abreviatura: 'MA' },
    { nombre: 'Mauricio', abreviatura: 'MU' },
    { nombre: 'Mauritania', abreviatura: 'MR' },
    { nombre: 'Micronesia', abreviatura: 'FM' },
    { nombre: 'Moldavia', abreviatura: 'MD' },
    { nombre: 'Mónaco', abreviatura: 'MC' },
    { nombre: 'Mongolia', abreviatura: 'MN' },
    { nombre: 'Montenegro', abreviatura: 'ME' },
    { nombre: 'Mozambique', abreviatura: 'MZ' },
    { nombre: 'Namibia', abreviatura: 'NA' },
    { nombre: 'Nauru', abreviatura: 'NR' },
    { nombre: 'Nepal', abreviatura: 'NP' },
    { nombre: 'Nicaragua', abreviatura: 'NI' },
    { nombre: 'Níger', abreviatura: 'NE' },
    { nombre: 'Nigeria', abreviatura: 'NG' },
    { nombre: 'Noruega', abreviatura: 'NO' },
    { nombre: 'Nueva Zelanda', abreviatura: 'NZ' },
    { nombre: 'Omán', abreviatura: 'OM' },
    { nombre: 'Países Bajos', abreviatura: 'NL' },
    { nombre: 'Pakistán', abreviatura: 'PK' },
    { nombre: 'Palaos', abreviatura: 'PW' },
    { nombre: 'Panamá', abreviatura: 'PA' },
    { nombre: 'Papúa Nueva Guinea', abreviatura: 'PG' },
    { nombre: 'Paraguay', abreviatura: 'PY' },
    { nombre: 'Perú', abreviatura: 'PE' },
    { nombre: 'Polonia', abreviatura: 'PL' },
    { nombre: 'Portugal', abreviatura: 'PT' },
    { nombre: 'Reino Unido', abreviatura: 'GB' },
    { nombre: 'República Centroafricana', abreviatura: 'CF' },
    { nombre: 'República Checa', abreviatura: 'CZ' },
    { nombre: 'República Democrática del Congo', abreviatura: 'CD' },
    { nombre: 'República Dominicana', abreviatura: 'DO' },
    { nombre: 'Ruanda', abreviatura: 'RW' },
    { nombre: 'Rumania', abreviatura: 'RO' },
    { nombre: 'Rusia', abreviatura: 'RU' },
    { nombre: 'Samoa', abreviatura: 'WS' },
    { nombre: 'San Cristóbal y Nieves', abreviatura: 'KN' },
    { nombre: 'San Marino', abreviatura: 'SM' },
    { nombre: 'San Vicente y las Granadinas', abreviatura: 'VC' },
    { nombre: 'Santa Lucía', abreviatura: 'LC' },
    { nombre: 'Santo Tomé y Príncipe', abreviatura: 'ST' },
    { nombre: 'Senegal', abreviatura: 'SN' },
    { nombre: 'Serbia', abreviatura: 'RS' },
    { nombre: 'Seychelles', abreviatura: 'SC' },
    { nombre: 'Sierra Leona', abreviatura: 'SL' },
    { nombre: 'Singapur', abreviatura: 'SG' },
    { nombre: 'Siria', abreviatura: 'SY' },
    { nombre: 'Somalia', abreviatura: 'SO' },
    { nombre: 'Sri Lanka', abreviatura: 'LK' },
    { nombre: 'Suazilandia', abreviatura: 'SZ' },
    { nombre: 'Sudáfrica', abreviatura: 'ZA' },
    { nombre: 'Sudán', abreviatura: 'SD' },
    { nombre: 'Sudán del Sur', abreviatura: 'SS' },
    { nombre: 'Suecia', abreviatura: 'SE' },
    { nombre: 'Suiza', abreviatura: 'CH' },
    { nombre: 'Surinam', abreviatura: 'SR' },
    { nombre: 'Tailandia', abreviatura: 'TH' },
    { nombre: 'Tanzania', abreviatura: 'TZ' },
    { nombre: 'Tayikistán', abreviatura: 'TJ' },
    { nombre: 'Timor Oriental', abreviatura: 'TL' },
    { nombre: 'Togo', abreviatura: 'TG' },
    { nombre: 'Tonga', abreviatura: 'TO' },
    { nombre: 'Trinidad y Tobago', abreviatura: 'TT' },
    { nombre: 'Túnez', abreviatura: 'TN' },
    { nombre: 'Turkmenistán', abreviatura: 'TM' },
    { nombre: 'Turquía', abreviatura: 'TR' },
    { nombre: 'Tuvalu', abreviatura: 'TV' },
    { nombre: 'Ucrania', abreviatura: 'UA' },
    { nombre: 'Uganda', abreviatura: 'UG' },
    { nombre: 'Uruguay', abreviatura: 'UY' },
    { nombre: 'Uzbekistán', abreviatura: 'UZ' },
    { nombre: 'Vanuatu', abreviatura: 'VU' },
    { nombre: 'Vaticano', abreviatura: 'VA' },
    { nombre: 'Venezuela', abreviatura: 'VE' },
    { nombre: 'Vietnam', abreviatura: 'VN' },
    { nombre: 'Yemen', abreviatura: 'YE' },
    { nombre: 'Yibuti', abreviatura: 'DJ' },
    { nombre: 'Zambia', abreviatura: 'ZM' },
    { nombre: 'Zimbabue', abreviatura: 'ZW' },
  ];
  