import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// import Footer from "./Footer";
import Footer from './Footer';


import './fonts.css';
import './style.css';
//

import NavBar from './NavBar';
import routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

  

  const containerRef = useRef(null);
  const [isContentLong, setIsContentLong] = useState(false);

  useEffect(() => {
    
    const handleResize = () => {
      const container = containerRef.current;
      if (container) {
        const hasVerticalScrollbar = container.scrollHeight > container.clientHeight; 
        setIsContentLong(!hasVerticalScrollbar);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  const cambio  =(tamanio) =>{   

    const container = containerRef.current;
    if (container) {
      const hasVerticalScrollbar = container.scrollHeight > container.clientHeight; 
      setIsContentLong(!hasVerticalScrollbar);
    }
  }

  return (
    <React.StrictMode>
      {/* <BrowserRouter basename="/demo"> */}
      <BrowserRouter basename="/">
        <NavBar cambio={cambio} />
        <div ref={containerRef} className="container cont-parent mt-4">
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Routes>
        </div>
        <Footer styler={isContentLong ? 'relative' : 'fixed' } />
      </BrowserRouter>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
