import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../style.css';
import { NavLink } from 'react-router-dom';


export const Concurso = () => {

    const basename = new URL(document.baseURI).pathname;
    console.log(basename)


    const images = [
        '/assets/imagen1.jpg',
        '/assets/imagen2.jpg',
        '/assets/imagen3.jpg'
    ];

    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: true,
        
    };






    const obtenerFragmentoDeURL = () => {
        const fragmento = window.location.hash.substring(1);

        if (fragmento) {
            navegarAID(fragmento);
        }
    
      };
      
      const navegarAID = (id) => {
        const elemento = document.getElementById(id);
        if (elemento) {
          elemento.scrollIntoView({ behavior: "smooth" });
        }
      };
      

      const iniciarFuncionDespuesDeRetraso = () => {
        setTimeout(obtenerFragmentoDeURL, 500); // Espera 0.5 segundos (500 milisegundos) antes de llamar a obtenerFragmentoDeURL
      };
      
      iniciarFuncionDespuesDeRetraso();
      
      

    const [fragmento, setFragmento] = useState('');

    useEffect(() => {
        const manejarCambioURL = () => {
            console.log("sdsdasdas")
        const nuevoFragmento = window.location.hash.substring(1);
        if (nuevoFragmento) {
            console.log("cambio")
            setFragmento(nuevoFragmento);
            navegarAID(nuevoFragmento);
        }
        };

        manejarCambioURL();

        // Agregar un listener para detectar cambios en la URL
        window.addEventListener('hashchange', manejarCambioURL);

        // Remover el listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('hashchange', manejarCambioURL);
        };
    }, []); // El segundo argumento [] asegura que este efecto se ejecute solo una vez al montar el componente

      

  
  return (

    <div>
     

     


        {/* <section>
            <div className="container">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-end">
                    <div className="imgLogo mb-3 mb-md-0"> 
                        
                        <img src="../assets/big_logo.png" alt="El México de los mexicanos III" />
                    </div>
                    <div className="text-center text-md-end">
                        <img src="../assets/last.jpg" alt="pronto termina" />


                        <NavLink to="/registro" className="btn btn-primary btn-pink">
                            Registrar mi foto
                        </NavLink>                    

                    </div>
                </div>
            </div>
        </section> */}


        <section>
            <div className="container">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-end">
                    <div className="imgLogo mb-3 mb-md-0"> 
                        <img src="../assets/big_logo.png" alt="El México de los mexicanos III" />
                    </div>
                    <div className="text-center text-md-end d-flex flex-column align-items-center align-items-md-end">
                    <a href="https://fomentoculturalbanamex.org/noticia.html?idn=5398" target="_blank" rel="noopener noreferrer">
                    <img src="../assets/last.jpg" alt="pronto termina" className="mb-3 img-last"/>
                    </a>
                        
                        {/* <NavLink to="/registro" className="btn btn-primary btn-pink">
                            Registrar mi foto
                        </NavLink>                     */}
                    </div>
                </div>
            </div>
        </section>



            
        

        <section className="b-g">
            <div className="container f-d  uppercase24L">
                <p>PUEDES PARTICIPAR EN DISTINTAS CATEGORÍAS<br />
                FOTOGRAFIANDO <b className="stg">EL MÉXICO CONTEMPORÁNEO</b><br />
                Y GANAR PREMIOS EN USD<br />
                ¡ES MUY FÁCIL!</p>
            </div>
        </section>

        <section>
            <div className="container f-d">
                <div className="row">

                    <div className="col uppercase24B">
                        <p>PREMIO BANCO NACIONAL DE MÉXICO 140 AÑOS</p>

                        <div className="col uppercase18R">
                        <p>

                            PREMIO BANCO NACIONAL DE MÉXICO 140 AÑOS: $ 24,000 USD EN EFECTIVO<br /><br />

                            PRIMER LUGAR: 2 premios de $ 12,000 USD EN EFECTIVO<br /><br />

                            SEGUNDO LUGAR: 2 premios de $ 6,000 USD EN EFECTIVO<br /><br />

                            TERCER LUGAR: 2 premios de $ 4,000 USD EN EFECTIVO<br /><br />

                            MENCIÓN HONORÍFICA: 5 premios de $ 1,000 USD EN EFECTIVO</p>
                            *Estos premios pueden derivarse de todas las categorías participantes a criterio del jurado.
                        </div>

                         <div className="spacer50">  </div>

                        <div className="col uppercase24B">

                        <p>RECONOCIMIENTOS</p>

                        <div className="col uppercase18R">
                        <p><b className="stg">MEJOR FOTOGRAFÍA REGIONAL</b><br /><br />
                            Se otorgarán 6 premios uno a cada una de las seis mejores fotografías regionales realizadas por cualquier Participante, cuya imagen ilustre de manera clara elementos que identifiquen plenamente a la región.<br /><br />
                            El ganador de cada una de las 6 regiones recibirá $2,000 USD en efectivo. <br />
                            <br />
                            
                            
                            <b className="stg">MEJOR FOTOGRAFÍA COLABORADOR CITIBANAMEX</b><br /><br />
                        Se otorgarán 5 reconocimientos, uno a cada una de las 5 mejores fotografías realizadas por colaboradores. <br /><br />


                            <b className="stg">LIBRO CONMEMORATIVO</b><br /><br />

                            <b className="stg">Se editará el libro conmemorativo "El México de los mexicanos III" que presentará las imágenes premiadas, menciones honoríficas y otras seleccionadas con base en mérito artístico fotográfico.  </b>
                            </p>
                        </div>

                            <div className="spacer50">  </div>
                            
                        </div>
                    </div>
                    

                    <div className="col uppercase24B">
                        <p>CATEGORIAS ESPECIALES</p>

                        <div className="col uppercase18R">
                        <p>PREMIO DEL JURADO $ 20,000 USD EN EFECTIVO<br />
                            8 fotografías sobre una comunidad cultural mexicana específica.<br /><br />

                            MÉXICO CONTEMPORÁNEO $ 10,000 USD EN EFECTIVO<br />
                            Fotografías de obras o actividades científicas, tecnológicas, culturales o arquitectónicas.<br /><br />

                            LA FAMILIA MEXICANA $ 5,000 USD EN EFECTIVO<br />
                            Fotografía de una escena de familia.<br /><br />

                            COMPROMISO SOCIAL $ 5,000 USD EN EFECTIVO<br />
                            Fotografía de alguna actividad realizada en favor de las comunidades.<br /><br />

                            FOMENTO CULTURAL CITIBANAMEX $ 5,000 USD EN EFECTIVO<br /> 
                            Fotografía de arte contemporáneo, como escultura, mural, arte popular, entre otros.<br /><br />

                            CONSERVACIÓN DEL MEDIO AMBIENTE $ 5,000 USD EN EFECTIVO<br /> 
                            Fotografía de alguna actividad que represente la conservación del medio ambiente en México.<br /><br />

                            LOS NIÑOS DE MÉXICO $ 5,000 USD EN EFECTIVO<br />
                            Fotografía de uno o más niños mexicanos que reflejen el México del futuro.<br /><br />

                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className='b-b'>
            <div className="container f-d uppercase24L">
                <p className="t-w">
                <mark>¡SE EXTIENDE EL PLAZO! NUEVA FECHA LÍMITE PARA REGISTRARTE.</mark><br /><br />
                CONVOCATORIA ABIERTA DESDE LAS 12 HORAS DEL 24 DE MAYO A LAS 23 HORAS DEL 30 DE SEPTIEMBRE DE 2024
                </p>
                <div className="text-end">
                <a href="../downloads/Bases_premio_fotografico_El_Mexico_de_los_mexicanos_3ra_edicion.pdf">
                <button className="btn btn-primary btn-blue" type="button">
                    Bases del concurso
                </button>
                </a> 
                </div>
       

            </div>
        </section>



        <section>
            <div className="container f-d">
                <div className="row">
                <div className="col uppercase24B">
                        <p>¿CÓMO SABER SI MI FOTO ES BUENA?</p>

                        <div className="spacer50">  </div>
                        
                        <div className="iframe-container">
                            <div className='vid-vimeo-parent'>
                                <iframe src="https://player.vimeo.com/video/929979765?h=c83d6240a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" className='vid-vimeo' title="MDM_FAQ">
                                </iframe>
                            </div>
                        </div>

                        <div className="spacer50">  </div>


                    </div>
                </div>

                <div className="row" id='faq'>
                <div className="spacer50">  </div>
                <div className="col uppercase24B">
                        <p>PREGUNTAS FRECUENTES</p>
                    </div>
                </div>
                
                <div className="spacer50">  </div>


                <div className="row">

                    <div className="col uppercase18R">
                        <p><b className="stg">¿QUÉ HAGO SI NO RECIBO MI CÓDIGO DE CONFIRMACIÓN?</b><br /><br />
                        1.	Revisar el “Correo no deseado” o SPAM<br />
                        2.	Si no lo encuentras ahí mándanos correo a <a href="mailto:contacto@elmexicodelosmexicanos.com"><b className="stg">contacto@elmexicodelosmexicanos.com</b></a> (Si tienes tu número de registro anotado mándalo junto con tus datos)
                         <br /><br />

                            <b className="stg">¿CÓMO PUEDO SABER SI MIS FOTOS TIENEN TANTO EL FORMATO COMO EL TAMAÑO DE ARCHIVO SOLICITADOS?</b><br /><br />
                            <b className="stg">ANDROID:</b> <br /><br />

                        1. Abrir la app Galería<br />
                        2. Selecciona la foto deseada.<br />
                        3. Toca el icono ••• en la esquina de la derecha extrema. <br />
                        4. Desplazarse hacia abajo y selecciona Detalles.<br />
                        5. El tamaño del archivo aparece abajo de Tamaño de imagen<br /><br />

                            <b className="stg">iPHONE:</b> <br /><br />

                            1. Abrir galería de Fotos<br />
                            2. Tocar el botón “i” de información<br />
                            3. Ahí podrás consultar la resolución y tamaño de archivo<br />
                            <br />
                            <br />

                            <b className="stg">¿CÓMO ME VAN A AVISAR SI GANO UN PREMIO?</b><br /><br />
                            Se contactará a los ganadores por correo electrónico contacto@elmexicodelosmexicanos.com a más tardar el 30 de noviembre de 2024. <br /><br />
                            <br />

                            <b className="stg">¿QUÉ PASARÁ SI PIERDO POR ACCIDENTE O MAL MANEJO DE EQUIPO LA INFO DE MIS FOTOS ENVIADAS? </b><br /><br />
                            El concurso guarda toda la información necesaria para tu participación desde el momento de la confirmación de tu registro. <br /><br />
                            <br />

                            <b className="stg">¿QUÉ PASA SI NO TENGO UNA IDENTIFICACIÓN OFICIAL?</b><br /><br />
                            Opciones de Identificación oficial: <br /><br />
                            - Credencial para votar vigente, expedida por el Instituto Nacional Electoral <br />
                            - Pasaporte vigente<br />
                            - Cédula profesional vigente con fotografía<br />
                            - Credencial del Instituto Nacional de las Personas Adultas Mayores vigente<br /><br />

                            Sin una Identificación oficial vigente no es posible participar en el concurso.
                            <br />
                            <br />

                            <b className="stg">¿IMPORTA SI MIS FOTOS SON ESCANEADAS DESDE UNA IMPRESIÓN EN PAPEL O UNA TRANSPARENCIA?</b><br /><br />
                        No, al contrario, son bienvenidos estos archivos siempre que estén de manera digital y cumplan con los requisitos de resolución y tamaño de archivo. <br /><br /><br />
                            
                            </p>
                        </div>


                        <div className="col uppercase18R">
                        <p>

                            <b className="stg">¿HASTA QUÉ PUNTO PUEDO UTILIZAR LAS HERRAMIENTAS DE LOS PROGRAMAS DIGITALES PARA MEJORAR LA IMAGEN?</b><br /><br />
                            Pueden utilizarse programas de edición para modificar: color, encuadre, resolución y/o contraste.<br />
                            No se pueden extraer o incluir elementos de otras imágenes diferentes a la original.<br />
                            No se permite la participación de imágenes creadas a través de cualquier programa de la inteligencia artificial (AI).<br />
 <br />
                            <br />

                            <b className="stg">¿QUÉ PASA SI DESPUÉS DE HABER HECHO MI ENVÍO DE FOTOS ME DOY CUENTA QUE ME HE EQUIVOCADO DE CATEGORÍA?</b><br /><br />
                            Contactános por correo electrónico a <a href="mailto:contacto@elmexicodelosmexicanos.com"><b className="stg">contacto@elmexicodelosmexicanos.com</b></a> con los siguientes datos:<br />
                            -	número de usuario<br />
                            -	por cuál categoría sería el cambio<br /><br />

                            Una vez revisada la petición y en caso de que corresponda el cambio a la nueva categoría, recibirán un correo de confirmación de nuestra parte.
 <br />
                            <br />

                            <b className="stg">¿PUEDO PARTICIPAR EN MÁS DE UNA CATEGORÍA?</b><br /><br />
                            El participante puede enviar hasta un límite de 5 fotografías para ser
                            incluidas en una o varias de las categorías que se mencionan en la convocatoria.<br /> <br /> 
                            La única excepción será para aquellos que participen en la categoría “Premio del Jurado” en la cual se requiere participar con un ensayo de 8 fotografías sobre una comunidad cultural mexicana específica.
<br />
                            <br />

                            <b className="stg">¿PIERDO LOS DERECHOS DE AUTOR DE MI OBRA SI RESULTA SELECCIONADA PARA LA COLECCIÓN FOMENTO CULTURAL CITIBANAMEX O GANADORA DE UN PREMIO O MENCIÓN HONORIFICA?</b><br /><br />
                            Las condiciones de participación están claramente establecidas en las Bases del Concurso en el punto 9. Te sugerimos leer cuidadosamente las bases antes de enviarnos cualquier fotografía.
<br />
                            <br />

                            <b className="stg">SOY EXTRANJERO, ¿PUEDO PARTICIPAR EN EL CONCURSO EL MÉXICO DE LOS MEXICANOS?</b><br /><br />
                            Pueden participar todos los mayores de edad independientemente de su nacionalidad y/o residencia. <br /><br /><br />

                            </p>
                        </div>
                </div>



            </div>
        </section>


        <section className='b-g'>
        <div className="container f-d  uppercase24L">
                <p>INFORMES<br /> 
                
                Correo electrónico: <a href="mailto:contacto@elmexicodelosmexicanos.com"><b className="stg">contacto@elmexicodelosmexicanos.com</b></a>
                </p>
            </div>
        </section>

    </div>)

  }
