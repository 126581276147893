
import {Concurso} from './components/Concurso';
import {Registro} from './components/Registro';
import {Ediciones} from './components/Ediciones';
import {Faq} from './components/Faq';
import {Bases} from './components/Bases';


const routes = [
  { path: '/', component: <Concurso /> },    
  // { path: '/registro', component: <Registro /> },
  { path: '/registrovip', component: <Registro /> },
  { path: '/bases', component: <Bases /> },
  { path: '/ediciones', component: <Ediciones /> },
  { path: '/faq', component: <Faq /> },
];

export default routes;