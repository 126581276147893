import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../style.css';
import AWS from 'aws-sdk';
import { estados } from './estados';

import { categorias } from './Paremters'; // Asegúrate de proporcionar la ruta correcta al archivo Parametres.jsx


export const RegistroP3A = ({ onNextStep , userId , registrarInfo}) => {

  const basename = new URL(document.baseURI).pathname;
  const [botonVisible, setBotonVisible] = useState(true);
  const [errorMensaje, setErrorMensaje] = useState('');
  const [imagenes, setImagenes] = useState([]);
  const [subiendo, setSubiendo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [showLightbox, setShowLightbox] = useState(false);
  
  const S3_BUCKET = 'mexico-delos-mexicanos';
  const REGION = 'us-east-1';
  const ACCESS_KEY = 'AKIAU6GDVOGKUGEPRWH6';
  const SECRET_ACCESS_KEY = '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';


  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------
  const onDrop = (acceptedFiles) => {
    if (imagenes.length < 5) {
      setErrorMensaje('');   
      const validFiles = acceptedFiles.filter(file =>
        file.type === 'image/jpeg' || file.type === 'image/jpg' || file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg')
      );

      // Si no hay archivos válidos, mostrar un mensaje de error
      if (validFiles.length === 0) {      
        setErrorMensaje('Error: Solo se permiten archivos JPG');
        return;
      }

      setImagenes((prevImagenes) => [
        ...prevImagenes,
        { file: validFiles[0], titulo: '', descripcion: '', categoria: '', lugar: '', estado: '' },
      ]);

      // Deshabilita el botón cuando se alcanzan 5 imágenes
      if (imagenes.length + validFiles.length >= 5) {
        setBotonVisible(false);
      }
    }
  };


  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------
  const eliminarImagen = (index) => {
    const nuevasImagenes = [...imagenes];
    nuevasImagenes.splice(index, 1);
    setImagenes(nuevasImagenes);

    // Habilita el botón si la cantidad de imágenes es menor a 5
    if (nuevasImagenes.length < 5) {
      setBotonVisible(true);
    }
  };

  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------
  const completeImages = () => {
    setImagenes([]);
    setSubiendo(false);
    setMensaje('¡Imágenes subidas correctamente!');

      onNextStep();
  }

  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------  

  const handleConfirmarRegistro = () =>{
    setShowLightbox(true);
  }

  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------

  const handleSubirImagenes  = async () => {

    setShowLightbox(false)
    if (imagenes.length === 0) {
      // Mostrar mensaje indicando que se espera al menos una imagen
      return;
    }

    // Validar campos de cada imagen
    const imagenConCampoFaltante = imagenes.find(
      (imagen) => !imagen.titulo || !imagen.descripcion || !imagen.categoria || !imagen.lugar || !imagen.estado
    );

    if (imagenConCampoFaltante) {
      const campoFaltante = Object.keys(imagenConCampoFaltante).find(
        (campo) => !imagenConCampoFaltante[campo]
      );
      setErrorMensaje(`El campo ${campoFaltante} es obligatorio. Por favor, complétalo.`);
      return;
    }

    for (let i = 0; i < imagenes.length; i++) {
      const imagen = imagenes[i];
      // Validar si la imagen es un archivo JPG

      
      if (!imagen.file || (!imagen.file.name.toLowerCase().endsWith('.jpg') && !imagen.file.name.toLowerCase().endsWith('.jpeg'))) {
        // Mostrar mensaje de error con el índice de la imagen
        setErrorMensaje(`La Imagen ${i + 1} (${imagen.file.name}) no es un archivo JPG o JPEG válido.`);
        return;
      }
      

      // Validar el tamaño del archivo (10 MB)
      const maxSize = 10 * 1024 * 1024; // 10 MB en bytes
      if (imagen.file.size > maxSize) {
        // Mostrar mensaje de alerta si el archivo supera el límite de tamaño
        setErrorMensaje(`La Imagen ${i + 1} (${imagen.file.name}) excede el tamaño máximo permitido (10 MB).`);
        return;
      }


      // Validar el tamaño del archivo (5 MB)
      // const minSize = 4.9 * 1024 * 1024; // 5 MB en bytes
      const minSize = 0.9 * 1024 * 1024; // 5 MB en bytes
      if (imagen.file.size < minSize) {
        // Mostrar mensaje de alerta si el archivo supera el límite de tamaño
        // setErrorMensaje(`La Imagen ${i + 1} (${imagen.file.name}) no tiene el tamaño minimo permitido (1 MB).`);
        setErrorMensaje(`La Imagen ${i + 1} (${imagen.file.name}) no tiene el tamaño minimo permitido (1 MB).`);
        return;
      }



      // Leer el archivo para obtener sus dimensiones
      const reader = new FileReader();
      reader.readAsDataURL(imagen.file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          // Validar las dimensiones de la imagen (200x200 píxeles)
          if (img.width < 200 || img.height < 200) {
            // Mostrar mensaje de error si la imagen tiene dimensiones insuficientes
            setErrorMensaje(`La Imagen ${i + 1} (${imagen.file.name}) debe tener al menos 200x200 píxeles.`);
            return;
          }
        };
      };

    }


    // Limpiar mensaje de error si no hay errores
    setErrorMensaje('')  
    // Resto del código...
    handleSubirImagenesPaso2()

  };

  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------


  const handleSubirImagenesPaso2 = async () => {
        
    setSubiendo(true);
    document.body.style.overflow = 'hidden';

    //-------------------------------- Configurar AWS
    AWS.config.update({        
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });

    const s3 = new AWS.S3();
    const urls = [];

    let allImagesUploaded = true;

    for (const imagen of imagenes) {    
      if (!imagen.file) {
        console.error('Propiedad "file" no definida en una imagen:', imagen);
      }else{
        const nameImage = imagen.file.name
        const sinEspacios = nameImage.replace(/\s/g, '');
        // Eliminar caracteres especiales y acentos
        const sinCaracteresEspeciales = sinEspacios.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    
        const params = {
          Bucket: S3_BUCKET,
          Key: `${userId}_${sinEspacios}`,
          Body: imagen.file,
          ContentType: imagen.file.type,
        };

        try {
          const data = await s3.upload(params).promise();
          imagen.file = data.Location; 
          console.log('Imagen subida:', data.Location);
        } catch (error) {
          console.error('Error al subir la imagen:', error);
          allImagesUploaded = false;
        }

      }
    }


    // if (window.fbq) {
    //   window.fbq('trackCustom', 'RegistroFinalizado');
    //   console.log('Evento RegistroFinalizado enviado a Facebook Pixel');
    // } else {
    //   console.error('Facebook Pixel no está definido');
    // }


    // document.body.style.overflow = 'visible';
    // // setImagenes([]);
    // // setSubiendo(false);
    // // setMensaje('¡Imágenes subidas correctamente!');
    // registrarInfo(imagenes, completeImages);   
       

    if (allImagesUploaded) {
      if (window.fbq) {
        window.fbq('trackCustom', 'RegistroFinalizado');
        console.log('Evento RegistroFinalizado enviado a Facebook Pixel');
      } else {
        console.error('Facebook Pixel no está definido');
      }


      if (window.gtag) {
        // Envía el evento `registro_finalizado` a Google Analytics
        window.gtag('event', 'registro_finalizado', {
          'app_name': 'ElMexicoDeLosMexicanos',
          'screen_name': 'RegistrationForm'
        });
        console.log('Evento registro_finalizado enviado a Google Analytics');
      } else {
        console.error('Google Analytics no está definido');
      }
  
      document.body.style.overflow = 'visible';
      registrarInfo(imagenes, completeImages);    
    } else {
      alert('Hubo un problema al subir las imágenes. Por favor, intenta nuevamente.');
      document.body.style.overflow = 'visible';
      setSubiendo(false);
    }
      
  };


  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------
  const { getRootProps, getInputProps } = useDropzone({    
    accept: 'image/jpeg',
    onDrop,
  });


  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------

return (
  <div className='fu-h'>

    <section className="showcase">
      <div className="container">
        <div className="row g-0 text-center d-flex justify-content-xl-center item-sevicio">
          <div className="col-12 text-center my-auto showcase-text">
          <img src="./assets/paso3.jpg" width={337} height={70} alt="Paso 3" />
          </div>
          <div className="col-12 text-start my-auto showcase-text">
            <h2>FOTOGRAFÍAS</h2>
          </div>
          <p className="lead mb-0 text-left">
            <span className="text-left">
              Usa el recuadro para agregar tus fotografías, puedes participar hasta con 5 imágenes.
            </span>
          </p>
          <div className="linea-horizontal"></div>
        </div>
      </div>
    </section>



    <section className="showcase">

    {imagenes.map((imagen, index) => (

        <div key={index} className="image-container">        
          
          <div className="label-container">
            <h3>FOTOGRAFÍA {index +1}</h3>
            <p>Agrega la información de tu fotografía</p>    
            <div >       
                {/* INPUT  */}
                <div className="if-row">
                  <div className="label">
                    <label>TÍTULO:</label>
                  </div>
                  <div className="input">
                    <input
                      type="text"
                      value={imagen.titulo}
                      onChange={(e) => {
                        const nuevasImagenes = [...imagenes];
                        nuevasImagenes[index].titulo = e.target.value;
                        setImagenes(nuevasImagenes);
                      }}
                    />
                  </div>
                </div>
                  
                {/* INPUT  */}
                <div className="if-row">
                  <div className="label">
                    <label>DESCRIPCIÓN:</label>
                  </div>
                  <div className="input">
                  <textarea
                      rows="3"                    
                      value={imagen.descripcion}
                      onChange={(e) => {
                        const nuevasImagenes = [...imagenes];
                        nuevasImagenes[index].descripcion = e.target.value;
                        setImagenes(nuevasImagenes);
                      }}
                    />
                  </div>
                </div>
                  
                {/* INPUT  */}
                <div className="if-row">
                  <div className="label">
                    <label>CATEGORÍA:</label>
                  </div>
                  <div className="input">
                    <select
                      value={imagen.categoria}
                      onChange={(e) => {
                        const nuevasImagenes = [...imagenes];
                        nuevasImagenes[index].categoria = e.target.value;
                        setImagenes(nuevasImagenes);
                      }}
                    >
                      <option value="" disabled>
                        Selecciona una categoría
                      </option>
                      {categorias.map((categoria) => (
                        <option key={categoria.id} value={categoria.id}>
                          {categoria.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                  
                {/* INPUT  */}
                <div className="if-row">
                  <div className="label">
                    <label>LUGAR:</label>
                  </div>
                  <div className="input">
                    <input
                      type="text"
                      value={imagen.lugar}
                      onChange={(e) => {
                        const nuevasImagenes = [...imagenes];
                        nuevasImagenes[index].lugar = e.target.value;
                        setImagenes(nuevasImagenes);
                      }}
                    />
                  </div>
                </div>
                  
                {/* INPUT  */}
                <div className="if-row">
                  <div className="label">
                    <label>ESTADO:</label>
                  </div>
                  <div className="input">
                    <select
                      value={imagen.estado}
                      onChange={(e) => {
                        const nuevasImagenes = [...imagenes];
                        nuevasImagenes[index].estado = e.target.value;
                        setImagenes(nuevasImagenes);
                      }}
                    >

                        <option value="" disabled>Selecciona un estado</option>
                      {estados.map((estado) => (
                        <option key={estado.nombre} value={estado.nombre}>
                          {estado.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
            </div> 

          </div>{/* END label-container  */}

                        
          {/*-------------------------------------- Eliminar */}           
          <div className="image-preview">

            <button onClick={() => eliminarImagen(index)} className="delete-button btn-white"  >
              <img src="./assets/eliminar.jpg" alt="Eliminar" className="delete-icon delete-icon-img" />
              {/* Eliminar */}
            </button>

            <div className="center-container frame">

              {imagen.file instanceof Blob && (
                <img
                  src={URL.createObjectURL(imagen.file)}
                  alt={`Imagen ${index}`}
                  className="preview-image"
                />
              )}
              
            </div>

          </div>

        </div>
      ))}


      {botonVisible && (
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />

        <div  className='ico-selecccion-img bi-sim'>
          <img src="./assets/upload.jpg" width={74} height={87} alt="Selecciona una imagen" />                      
        </div>

        <p className="">
          <span className="instrucciones">Arrastra aquí tu imagen o haz clic para seleccionar tu foto</span>
        </p>
      </div>
    )}

    <div className="notas">
      <p>Peso mínimo 5 MB - máximo 10 MB<br></br>Resolución 300 dpi<br></br>Formato JPG</p>      
    </div>
    
    <div className="col-12 col-xl-8 text-start my-auto order-lg-1 showcase-text align-items-center">
      {/* Manejo de errores */}
      {errorMensaje && (
        <p style={{ color: 'DeepPink' }}>
          {errorMensaje}
        </p>
      )}
    </div>

    {imagenes.length > 0 && (
      <div className="buttons-container text-center">        
        <button
          className="continue-button btn-pink mx-auto"
          onClick={ handleConfirmarRegistro}//handleConfirmarRegistro
        >
          Finalizar registro
        </button>
      </div>
    )}

    {subiendo && <div className="overlay"></div>}
    {subiendo && (
      <div className="upload-message mx-auto">Subiendo imágenes...</div>
    )}
    {mensaje && <div className="success-message mx-auto">{mensaje}</div>}

    {/*  */}


    {showLightbox && (
      <div className="overlayFin">
        <div className="lightbox-container">
          <div className="lightbox-content">
            <p>
            Asegúrate de subir todas tus imágenes en este paso, ya que no es posible registrarlas en otra sesión. Al finalizar el registro, tu solicitud será enviada para consideración de los jueces y no podrá ser modificada.
            </p>
            <p>¿Deseas continuar?</p>
            <div className="buttons-container">
              <button className="continue-button btn-black" onClick={() => setShowLightbox(false)}>
                Regresar
              </button>
              <button className="continue-button btn-black" onClick={handleSubirImagenes}>
                Si, finalizar registro
              </button>
            </div>
          </div>
        </div>
      </div>
    )}


  </section>

  </div>
);
};
