import React, { useState } from 'react';
import '../style.css';

export const RegistroP0 = ({ onNextStep }) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const [contestChecked, setContestChecked] = useState(false);

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleContestChange = () => {
    setContestChecked(!contestChecked);
  };

  const handleContinueClick = () => {
    // Verificar si ambos checkboxes están marcados antes de continuar
    if (termsChecked && contestChecked) {
      onNextStep(); // Llamada a la función de continuar solo si ambos checkboxes están marcados
    } else {
      // Mostrar un mensaje indicando que ambos checkboxes deben estar marcados
      alert('Debes aceptar ambos términos y condiciones para continuar.');
    }
  };

  return (
    <div className='fu-h'>
      <section className="showcase">
        <div className="container">
          <div className="row g-0 td-flex justify-content-xl-center item-sevicio">
            <div className="col-12  my-auto order-lg-1 showcase-text">
              <h2>QUEREMOS VER A MÉXICO A TRAVÉS DE TUS OJOS</h2>
              <p className="lead mb-0">
                <span className="text-left">
                Este concurso no solo es una oportunidad para mostrar tu talento como fotógrafo, sino también para explorar y resaltar las maravillas que hacen de nuestro país un lugar único. Cada imagen tiene el poder de contar una historia, y queremos que compartas la tuya. Queremos ver México a través de tus ojos.
                </span>
              </p>

              <div className="spacer50"></div>

              <div className="check-home">
                <div className="form-check">
                  <input
                    id="formCheck-1"
                    className="form-check-input"
                    type="checkbox"
                    checked={termsChecked}
                    onChange={handleTermsChange}
                  />
                  <label className="form-check-label" htmlFor="formCheck-1">
                  He leído y acepto <a href="../downloads/Bases_premio_fotografico_El_Mexico_de_los_mexicanos_3ra_edicion.pdf" target="_blank">las bases del concurso.</a>
                  </label>
                </div>
              </div>

              <div className="check-home">
                <div className="form-check">
                  <input
                    id="formCheck-2"
                    className="form-check-input"
                    type="checkbox"
                    checked={contestChecked}
                    onChange={handleContestChange}
                  />
                  <label className="form-check-label" htmlFor="formCheck-2">
                  He leído y acepto <a href="https://www.banamex.com/compromiso-social/avisos.html" target="_blank">el aviso de privacidad.</a>
                  </label>
                </div>
              </div>

              <div className="spacer50"></div>

              <p className="lead mb-0 text-center">
                <br />
                <span>Tiempo estimado 10 min</span>
              </p>

              <div className="spacer50"></div>

              <div className="col-12 text-center my-auto order-lg-1 showcase-text">
                <button
                  className={`btn btn-primary ${(termsChecked && contestChecked) ? 'btn-pink' : 'btn-pink2'}`}
                  type="button"
                  onClick={handleContinueClick}
                  disabled={!termsChecked || !contestChecked}
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
