import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RegistroP0  } from './RegistroP0';
import { RegistroP1  } from './RegistroP1';
import { RegistroP2  } from './RegistroP2';
import { RegistroP3A } from './RegistroP3A';  // Importa RegistroP3_A
import { RegistroP3B } from './RegistroP3B';  // Importa RegistroP3_B
import { RegistroP4  } from './RegistroP4';
import { registraImagenesLibre, registraParticipante, registraImagenesEnsayo } from './Auxiliares';
import { URI_PATH } from './Paremters';

import AWS from 'aws-sdk';

export const Registro = () => {

  //const uripath = "http://localhost/jhc152/fcb/mexico/queries/"
  const uripath = URI_PATH
  
  


  const [folio, setFolio] = useState("");
  const [userId, setUserId] = useState(null);
  const [pasoCurrent, setPasoCurrent] = useState(0);
  const [tempData, setTempData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    edad: '',
    genero: '',
    pais: '',
    calle: '',
    idUrl: '',
    identificacionImagen: null,
    numeroExterior: '',
    numeroInterior: '',
    cp: '',  // Debería ser cp, no CSSPageRule
    municipio: '',
    estado: '',
    telefono: '',
  });
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);  // Nuevo estado

  const handleNextStep = () => {
    // Genera un ID temporal al avanzar al RegistroP1
    if (pasoCurrent === 0) {
      const temporaryUserId = uuidv4();
      setUserId(temporaryUserId);
    }

    // Almacena temporalmente la información capturada en RegistroP1
    if (pasoCurrent === 1) {
      setTempData((prevData) => ({
        ...prevData,
        // Agrega más campos según sea necesario
        nombre: prevData.nombre,
        apellidos: prevData.apellidos,
        email: prevData.email,
        edad: prevData.edad,
        genero: prevData.genero,
        pais: prevData.pais,
        calle: prevData.calle,
        idUrl: prevData.idUrl,
        identificacionImagen: prevData.identificacionImagen,
        numeroExterior: prevData.numeroExterior,
        numeroInterior: prevData.numeroInterior,
        cp: prevData.cp,
        municipio: prevData.municipio,
        estado: prevData.estado,
        telefono: prevData.telefono,
      }));
    }

    // Avanza al siguiente paso
    setPasoCurrent(pasoCurrent + 1);
  };





  const registrarInfo = async (imagenesData, completeImages) => {
    console.log("mando informacion");
    const imgURL = await subirImagenS3(tempData.identificacionImagen);
  
    // Actualizar el estado
    setTempData({
      ...tempData,
      idUrl: imgURL,
    });
  
    // Esperar a que se actualice el estado antes de ejecutar el código posterior
    setTimeout(async () => {
      console.log("tempData: " + tempData.idUrl);
  
      setFolio("");
  
      const fol = await registraParticipante(userId, tempData, uripath, setFolio,imgURL);
  
      console.log("imprimiendo folio");
      console.log("folio " + fol);
      if (fol !== "") {
        await registraImagenesLibre(userId, imagenesData, uripath, tempData, fol);
      }
  
      completeImages();
    }, 0);
  };
  


  const registrarInfoEnsayo = async  (informacionGeneral,imagenesData, completeImages) =>{
    console.log("mando informacion")

    const imgURL = await subirImagenS3(tempData.identificacionImagen)

    setTempData({
      ...tempData,
      idUrl: imgURL,
    });

  
    
    
    const fol =await registraParticipante( userId, tempData,uripath, setFolio, imgURL)

    console.log("imprimiendo folio")
    console.log("folio " + folio);

    console.log("imprimiendo folio")
    console.log("folio " + fol);
    if (fol != "")
      await registraImagenesEnsayo( userId, informacionGeneral, imagenesData,uripath ,tempData, fol)

    completeImages();

  }




  











const subirImagenS3 = async (imagen) => {
  const S3_BUCKET = 'mexico-delos-mexicanos';
  const REGION = 'us-east-1';
  const ACCESS_KEY = 'AKIAU6GDVOGKUGEPRWH6';
  const SECRET_ACCESS_KEY = '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';

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
  });

  const s3 = new AWS.S3();

  const params = {
    Bucket: S3_BUCKET,
    Key: `${userId}_identificacion`, 
    Body: imagen,
    ContentType: imagen.type,
  };

  try {
    const data = await s3.upload(params).promise();
    console.log('Identificación subida a S3:', data.Location);
    return Promise.resolve(data.Location);  // Devuelve la URL de la imagen en S3
  } catch (error) {
    console.error('Error al subir la identificación a S3:', error);
    return null;
  }
};





  return (
    <div>
      {pasoCurrent === 0 && <RegistroP0 onNextStep={handleNextStep} />}
      
      {pasoCurrent === 1 && <RegistroP1 userId={userId} onNextStep={handleNextStep} tempData={tempData} setTempData={setTempData} />}

      {pasoCurrent === 2 && <RegistroP2 onNextStep={handleNextStep} setCategoriaSeleccionada={setCategoriaSeleccionada} />}
      
      {/* Determina qué componente cargar en base a la categoría seleccionada */}
      {pasoCurrent === 3 && categoriaSeleccionada === 'Categoria Libre' && <RegistroP3A onNextStep={handleNextStep} userId={userId}  registrarInfo = {registrarInfo} />}
      {pasoCurrent === 3 && categoriaSeleccionada === 'Categoria Ensayo' && <RegistroP3B onNextStep={handleNextStep} userId={userId}  registrarInfo = {registrarInfoEnsayo} />}
      
      {pasoCurrent === 4 && <RegistroP4 userId={folio}  />}
    </div>
  );
};
