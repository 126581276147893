import React, { useState } from 'react';
import '../style.css';

export const RegistroP2 = ({ onNextStep, setCategoriaSeleccionada }) => {
  const handleCategoriaLibreClick = () => {
    setCategoriaSeleccionada('Categoria Libre');
    onNextStep();
  };

  const handleCategoriaEnsayoClick = () => {
    setCategoriaSeleccionada('Categoria Ensayo');
    onNextStep();
  };

  return (
    <div className='fu-h'>
      <section className="showcase">
        <div className="container">
          <div className="row g-0 text-center d-flex justify-content-xl-center item-sevicio">
            <div className="col-12" style={{ marginBottom: '50px' }}>
              <h2 style={{ fontWeight: 'bold' }}>¿CÓMO ES TU PARTICIPACIÓN?</h2>
              <h3 style={{ fontSize: '18px', color: 'deepPink'}}>Elige con cuidado, no podrás regresar a esta ventana</h3>
            </div>


            <div className="col-md-6">
              <div className="text-justify">
                {/* <h3>Premio del jurado</h3> */}
                <p><b className="stg">Premio del jurado</b>, que consiste en un ensayo fotográfico compuesto de 8 imágenes sobre una comunidad cultural mexicana específica.</p>
                <button
                  className="btn btn-primary btn-blue spacerTop50"
                  type="button"
                  onClick={handleCategoriaEnsayoClick}
                >
                  Premio del jurado ›
                </button>
              </div>
            </div>

            <div className="col-md-6">
              <div className="text-justify">
                {/* <h3>Otras categorías</h3> */}
                <p><b className="stg">Categorías especiales</b>, hasta con 5 imágenes en total, en una o varias categorías de tu elección.</p>
                <button
                  className="btn btn-primary btn-pink spacerTop50"
                  type="button"
                  onClick={handleCategoriaLibreClick}
                >
                  Categorías especiales ›
                </button>
              </div>
            </div>
            
        
          </div>
        </div>
      </section>
    </div>
  );
};
