// "https://sweetandsourstudio.com/team/umami/mdm/api/queries/"
export const URI_PATH = "https://www.elmexicodelosmexicanos.com/api/"




// Parametres.jsx

export const categorias = [
    { id: 1, nombre: "México Contemporáneo" },
    { id: 2, nombre: "La Familia Mexicana" },
    { id: 3, nombre: "Compromiso Social" },
    { id: 4, nombre: "Fomento Cultural Citibanamex" },
    { id: 5, nombre: "Conservación del Medio Ambiente" },
    { id: 6, nombre: "Los Niños de México" },
    { id: 7, nombre: "Colaborador Citibanamex" },
    { id: 8, nombre: "Categoría abierta" },
  ];
  