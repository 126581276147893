import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../style.css';
import AWS from 'aws-sdk';
import { estados } from './estados';

export const RegistroP3B = ({ onNextStep, userId, registrarInfo }) => {
  const [botonVisible, setBotonVisible] = useState(true);
  const [errorMensaje, setErrorMensaje] = useState('');

  const [informacionGeneral, setInformacionGeneral] = useState({
    titulo: '',
    descripcion: '',
    lugar: '',
    estado: '',
  });

  const [imagenes, setImagenes] = useState([]);
  const [subiendo, setSubiendo] = useState(false);
  const [mensaje, setMensaje] = useState('');

  const [showLightbox, setShowLightbox] = useState(false);

  const S3_BUCKET = 'mexico-delos-mexicanos';
  const REGION = 'us-east-1';
  const ACCESS_KEY = 'AKIAU6GDVOGKUGEPRWH6';
  const SECRET_ACCESS_KEY = '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';


  const handleConfirmarRegistro = () =>{
    setShowLightbox(true);
  }


  const onDrop = (acceptedFiles) => {
    if (imagenes.length < 8) {

    setErrorMensaje('');

      // Filtrar archivos JPG
    const jpgFiles = acceptedFiles.filter(file =>
      file.type === 'image/jpeg' || file.type === 'image/jpg' || file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg')
    );

    // Si no hay archivos JPG, mostrar un mensaje de error y retornar
    if (jpgFiles.length === 0) {
      setErrorMensaje('Error: Solo se permiten archivos JPG');
      return;
    }

    // Filtrar archivos que no exceden el tamaño máximo
    const validFiles = jpgFiles.filter(file =>
      file.size <= 10 * 1024 * 1024
    );

    // Si no hay archivos válidos (que cumplan ambos criterios), mostrar un mensaje de error y retornar
    if (validFiles.length === 0) {
      setErrorMensaje('Error: Los archivos JPG deben ser menores o iguales a 10 MB');
      return;
    }


    const validFilesMin = jpgFiles.filter(file =>
      // file.size >= 4.9 * 1024 * 1024
      file.size >= 0.9 * 1024 * 1024
    );



    // Si no hay archivos válidos (que cumplan ambos criterios), mostrar un mensaje de error y retornar
    if (validFilesMin.length === 0) {
      setErrorMensaje('Error: Los archivos JPG deben ser minimo a 1 MB');
      return;
    }








      setImagenes((prevImagenes) => [
        ...prevImagenes,
        {
          file: acceptedFiles[0],
        },
      ]);

      // Deshabilita el botón cuando se alcanzan 8 imágenes
      if (imagenes.length + 1 === 8) {
        setBotonVisible(false);
      }
    }
  };

  const eliminarImagen = (index) => {
    const nuevasImagenes = [...imagenes];
    nuevasImagenes.splice(index, 1);
    setImagenes(nuevasImagenes);

    // Habilita el botón si la cantidad de imágenes es menor a 8
    if (nuevasImagenes.length < 8) {
      setBotonVisible(true);
    }
  };

  const eliminarTodas = () => {
    setImagenes([]);
    setBotonVisible(true);
  };

  const completeImages = () => {
    setImagenes([]);
    setSubiendo(false);
    setMensaje('¡Imágenes subidas correctamente!');
    onNextStep();
  };






  const handleSubirImagenesOld = async () => {
    setSubiendo(true);
    document.body.style.overflow = 'hidden';

    // Configurar AWS
    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });

    const s3 = new AWS.S3();

    for (const imagen of imagenes) {
      if (!imagen.file) {
        console.error('Propiedad "file" no definida en una imagen:', imagen);
      } else {
        const nameImage = imagen.file.name;
        const sinEspacios = nameImage.replace(/\s/g, '');
        // Eliminar caracteres especiales y acentos
        const sinCaracteresEspeciales = sinEspacios.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        const params = {
          Bucket: S3_BUCKET,
          Key: `${userId}_${sinEspacios}`, // Renombrar la imagen según tus requisitos
          Body: imagen.file,
          ContentType: imagen.file.type,
        };

        try {
          const data = await s3.upload(params).promise();
          imagen.file = data.Location;
          console.log('Imagen subida:', data.Location);
        } catch (error) {
          console.error('Error al subir la imagen:', error);
        }
      }
    }

    document.body.style.overflow = 'visible';
    registrarInfo(informacionGeneral, imagenes, completeImages);
  };

  const handleSubirImagenes = async () => {
     if (imagenes.length !== 8) {
      // Mostrar alerta indicando que se esperan exactamente 8 imágenes
      
      setErrorMensaje(`Se esperan exactamente 8 imágenes.`);
      return;
    }

    // Validar campos generales
    const campoFaltante = Object.keys(informacionGeneral).find(
      (campo) => !informacionGeneral[campo]
    );

    if (campoFaltante) {
      setErrorMensaje(`El campo ${campoFaltante} es obligatorio. Por favor, complétalo.`);
      return;
    }


    
    if (window.fbq) {
      window.fbq('trackCustom', 'RegistroFinalizado');
      console.log('Evento RegistroFinalizado enviado a Facebook Pixel');
    } else {
      console.error('Facebook Pixel no está definido');
    }


    if (window.gtag) {
      // Envía el evento `registro_finalizado` a Google Analytics
      window.gtag('event', 'registro_finalizado', {
        'app_name': 'ElMexicoDeLosMexicanos',
        'screen_name': 'RegistrationForm'
      });
      console.log('Evento registro_finalizado enviado a Google Analytics');
    } else {
      console.error('Google Analytics no está definido');
    }

    // Limpiar mensaje de error si no hay errores
    setErrorMensaje('');
    // Resto del código...
    handleSubirImagenesOld();
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif,',
    onDrop,
  });

  return (
    <div className='fu-h'>
      < section className="showcase">
        <div className="container">
          <div className="row g-0 text-center d-flex justify-content-xl-center item-sevicio">
          <div className="col-12 text-center my-auto showcase-text">
              <img src="./assets/paso3.jpg" width={337} height={70} alt="Paso 3" />
              </div>
            <div className="col-12 text-start my-auto showcase-text">
              <h2>FOTOGRAFÍAS</h2>
            </div>
            <p className="lead mb-0 text-left">
              <span className="text-left">
                Usa el recuadro para agregar tus fotografías. 8 imágenes en la categoría "Ensayo".
              </span>
            </p>
            <div className="linea-horizontal"></div>
          </div>
        </div>
      </section>


      <section className="showcase">


      {botonVisible && (
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} /><div  className='ico-selecccion-img bi-sim'>
                        <img src="./assets/upload.jpg" width={74} height={87} alt="Selecciona una imagen" />
                        
                    </div>

          <p className="">
                <span className="instrucciones">
                    Arrastra aquí tu imagen o haz clic para seleccionar tu foto</span>
                </p>
        </div>
      )}

          <div className="notas">
                  <p>Peso mínimo 5 MB - máximo 10 MB<br></br>Resolución 300 dpi<br></br>Formato JPG</p>                
          </div>  


        <div className="container">
          

            <div className="info-general-container">
              <div className="label-container">
                <h3>Agrega información de tus fotografías</h3>
              
              
                  <div className="if-row">
                    <div className="label">
                      <label>TÍTULO:</label>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        value={informacionGeneral.titulo}
                        onChange={(e) => setInformacionGeneral({ ...informacionGeneral, titulo: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="if-row">
                    <div className="label">
                      <label>DESCRIPCIÓN:</label>
                    </div>
                    <div className="input">
                      <textarea
                        rows="3"
                        value={informacionGeneral.descripcion}
                        onChange={(e) =>
                          setInformacionGeneral({ ...informacionGeneral, descripcion: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="if-row">
                    <div className="label">
                      <label>LUGAR:</label>
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        value={informacionGeneral.lugar}
                        onChange={(e) => setInformacionGeneral({ ...informacionGeneral, lugar: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="if-row">
                    <div className="label">
                      <label>ESTADO:</label>
                    </div>
                    <div className="input">
                      <select
                        value={informacionGeneral.estado}
                        onChange={(e) =>
                          setInformacionGeneral({ ...informacionGeneral, estado: e.target.value })
                        }
                      >
                        <option value="" disabled>
                          Selecciona un estado
                        </option>
                        {estados.map((estado) => (
                          <option key={estado.nombre} value={estado.nombre}>
                            {estado.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  </div>

              </div>
            

          
        </div>
      </section>



      <section className="showcase">
        <div className="container">
          <div className="row g-0 text-left d-flex justify-content-xl-center item-sevicio">
               





          {imagenes.map((imagen, index) => (
            <div key={index} className="image-container">
              <div className="label-container">
                <h3>FOTOGRAFÍA {index + 1}</h3>
              </div>


              <div className="image-preview">
                <button
                  onClick={() => eliminarImagen(index)}
                  className="delete-button btn-white"
                >
                <img src="./assets/eliminar.jpg" alt="Eliminar" className="delete-icon delete-icon-img" />

                </button>

                <div className="center-container">
                  {imagen.file instanceof Blob && (
                    <img
                      src={URL.createObjectURL(imagen.file)}
                      alt={`Imagen ${index}`}
                      className="preview-image"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}





          </div>
        </div>







         {/*  */}


      {showLightbox && (
        <div className="overlayFin">
          <div className="lightbox-container">
            <div className="lightbox-content">
              <p>
                Al finalizar tu registro, tu solicitud será enviada y registrada para consideración de los jueces y no podrá ser modificada.
              </p>
              <p>¿Deseas continuar?</p>
              <div className="buttons-container">
                <button className="continue-button btn-black" onClick={() => setShowLightbox(false)}>
                  Regresar
                </button>
                <button className="continue-button btn-black" onClick={handleSubirImagenes}>
                  Si, finalizar registro
                </button>
              </div>
            </div>
          </div>
        </div>
      )}








      </section>












      <div className="col-12 col-xl-8 text-start my-auto order-lg-1 showcase-text align-items-center">
        {/* Manejo de errores */}
        {errorMensaje && <p style={{ color: 'red' }}>{errorMensaje}</p>}
      </div>

      {imagenes.length > 0 && (
        <div className="buttons-container text-center">
          {/* <button
            className="delete-all-button btn-black mx-auto"
            onClick={() => eliminarTodas()}
          >
            Borrar Todas las Imágenes
          </button> */}
          <button
            className="continue-button btn-blue mx-auto"
            onClick={handleConfirmarRegistro}
          >
            Finalizar registro
          </button>
        </div>
      )}

      {subiendo && <div className="overlay"></div>}
      {subiendo && (
        <div className="upload-message mx-auto">Subiendo imágenes...</div>
      )}
      {mensaje && <div className="success-message mx-auto">{mensaje}</div>}
    </div>
  );
};
