import React from 'react';
import {  Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../style.css';

export const RegistroP4 = ({  userId }) => {


  return (
    <div className='fu-h'>


        
<section className="showcase">

        <div className="containerConfirm">
            <div className="row ">
                <div className="col-12 text-center my-auto order-lg-1 ">

                    <h2>Felicidades, ¡ya estás concursando!</h2>

                    <p className="lead mb-0"><br />                        
                        <span >
                        Gracias por participar en "El México de los mexicanos III". Mantente atento a nuestras actualizaciones y redes sociales.<br /> 
                        ¡Te deseamos la mejor de las suertes!
                        </span>
                        
                    </p>
                    <br/><br/>

                    <h3>Tu número de registro es</h3>
                    <h2>{  userId }</h2>
                    <br/>
                    <br/>
                    

                    <h3>¿Dudas?</h3>
                    <p className=" mb-0 fin" style={{ whiteSpace: 'nowrap' }}>
                        Consulta el <Nav.Link as={NavLink} to="/#faq" style={{ display: 'inline' }} className='btn-u'> FAQ </Nav.Link> o escríbenos a:{' '}<br />
                        <a href="mailto:contacto@elmexicodelosmexicanos.com" target="_blank">contacto@elmexicodelosmexicanos.com</a>
                    </p>


                   
                    
                 

                </div>
            </div>
        </div>

        </section>

    </div>
  );


};

