
 export const estados =    [
      // {
      //  "nombre": "No aplica para extranjeros",
      //  "abreviatura": "NA"
      // },
      {
        "nombre": "Aguascalientes",
        "abreviatura": "AGS"
      },
      {
        "nombre": "Baja California",
        "abreviatura": "BC"
      },
      {
        "nombre": "Baja California Sur",
        "abreviatura": "BCS"
      },
      {
        "nombre": "Campeche",
        "abreviatura": "CAM"
      },
      {
        "nombre": "Chiapas",
        "abreviatura": "CHP"
      },
      {
        "nombre": "Chihuahua",
        "abreviatura": "CHH"
      },
      {
        "nombre": "Ciudad de México",
        "abreviatura": "CDMX"
      },
      {
        "nombre": "Coahuila",
        "abreviatura": "COA"
      },
      {
        "nombre": "Colima",
        "abreviatura": "COL"
      },
      {
        "nombre": "Durango",
        "abreviatura": "DUR"
      },
      {
        "nombre": "Guanajuato",
        "abreviatura": "GTO"
      },
      {
        "nombre": "Guerrero",
        "abreviatura": "GRO"
      },
      {
        "nombre": "Hidalgo",
        "abreviatura": "HID"
      },
      {
        "nombre": "Jalisco",
        "abreviatura": "JAL"
      },
      {
        "nombre": "México",
        "abreviatura": "MEX"
      },
      {
        "nombre": "Michoacán",
        "abreviatura": "MIC"
      },
      {
        "nombre": "Morelos",
        "abreviatura": "MOR"
      },
      {
        "nombre": "Nayarit",
        "abreviatura": "NAY"
      },
      {
        "nombre": "Nuevo León",
        "abreviatura": "NL"
      },
      {
        "nombre": "Oaxaca",
        "abreviatura": "OAX"
      },
      {
        "nombre": "Puebla",
        "abreviatura": "PUE"
      },
      {
        "nombre": "Querétaro",
        "abreviatura": "QRO"
      },
      {
        "nombre": "Quintana Roo",
        "abreviatura": "QR"
      },
      {
        "nombre": "San Luis Potosí",
        "abreviatura": "SLP"
      },
      {
        "nombre": "Sinaloa",
        "abreviatura": "SIN"
      },
      {
        "nombre": "Sonora",
        "abreviatura": "SON"
      },
      {
        "nombre": "Tabasco",
        "abreviatura": "TAB"
      },
      {
        "nombre": "Tamaulipas",
        "abreviatura": "TAM"
      },
      {
        "nombre": "Tlaxcala",
        "abreviatura": "TLAX"
      },
      {
        "nombre": "Veracruz",
        "abreviatura": "VER"
      },
      {
        "nombre": "Yucatán",
        "abreviatura": "YUC"
      },
      {
        "nombre": "Zacatecas",
        "abreviatura": "ZAC"
      }
    ]
  
  