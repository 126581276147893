
import React from 'react';
import '../style.css';

export const Bases = () => {
    return (
        <div>
    <section className="showcase">
      <div className="container">
          <div className="col-12">
          <h1>Bases del concurso</h1>

          <div className="doubleSpace">
            <h4>Subtítulo</h4>
            <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu risus at nulla viverra ultrices sed imperdiet neque. Pellentesque pellentesque, mauris non pellentesque feugiat, sapien lacus tempor nulla, ac venenatis libero orci in velit. Donec lobortis quis purus eget placerat. Curabitur id neque fermentum, tristique orci eget, tempor enim. Vestibulum venenatis quam vel sapien mollis volutpat id vitae ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Interdum et malesuada fames ac ante ipsum primis in faucibus.

Aenean pellentesque sem metus, nec fringilla leo sodales et. Cras facilisis, turpis sit amet rhoncus sodales, dui risus sagittis libero, sit amet consequat quam ante eget quam. Cras viverra libero in magna varius faucibus. Aenean a augue sed neque condimentum ultrices eu vel est. Praesent pellentesque massa et placerat auctor. Sed aliquet urna a pharetra pretium. Sed cursus a risus vel varius. Donec tincidunt bibendum porta. Mauris vel ligula enim. Fusce nec elit ac diam posuere volutpat quis sed lorem. Aliquam erat volutpat. Vivamus non dapibus est.

Vestibulum pulvinar, dui sit amet porta consectetur, augue magna placerat mi, in suscipit nisl nibh eget risus. Ut vehicula tortor et dui lobortis vestibulum eget in dui. Cras rhoncus euismod nisl, sit amet congue nisi facilisis non. Ut finibus nisl et libero ornare placerat ut ut nunc. Vivamus vehicula eros quis ligula pellentesque fermentum. Phasellus a erat ultricies, convallis eros sed, suscipit nulla. Vivamus iaculis leo nec magna suscipit, in ornare mauris luctus. Aenean nec pretium felis. Etiam viverra efficitur purus, eget facilisis nisi sodales sed. Cras non fermentum diam, quis tempor enim.
            </p>
            </div>

            <div className="doubleSpace">
            <h4>Subtítulo</h4>
            <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu risus at nulla viverra ultrices sed imperdiet neque. Pellentesque pellentesque, mauris non pellentesque feugiat, sapien lacus tempor nulla, ac venenatis libero orci in velit. Donec lobortis quis purus eget placerat. Curabitur id neque fermentum, tristique orci eget, tempor enim. Vestibulum venenatis quam vel sapien mollis volutpat id vitae ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Interdum et malesuada fames ac ante ipsum primis in faucibus.

Aenean pellentesque sem metus, nec fringilla leo sodales et. Cras facilisis, turpis sit amet rhoncus sodales, dui risus sagittis libero, sit amet consequat quam ante eget quam. Cras viverra libero in magna varius faucibus. Aenean a augue sed neque condimentum ultrices eu vel est. Praesent pellentesque massa et placerat auctor. Sed aliquet urna a pharetra pretium. Sed cursus a risus vel varius. Donec tincidunt bibendum porta. Mauris vel ligula enim. Fusce nec elit ac diam posuere volutpat quis sed lorem. Aliquam erat volutpat. Vivamus non dapibus est.

Vestibulum pulvinar, dui sit amet porta consectetur, augue magna placerat mi, in suscipit nisl nibh eget risus. Ut vehicula tortor et dui lobortis vestibulum eget in dui. Cras rhoncus euismod nisl, sit amet congue nisi facilisis non. Ut finibus nisl et libero ornare placerat ut ut nunc. Vivamus vehicula eros quis ligula pellentesque fermentum. Phasellus a erat ultricies, convallis eros sed, suscipit nulla. Vivamus iaculis leo nec magna suscipit, in ornare mauris luctus. Aenean nec pretium felis. Etiam viverra efficitur purus, eget facilisis nisi sodales sed. Cras non fermentum diam, quis tempor enim.
            </p>
            </div>

            <div className="doubleSpace">
            <h4>Subtítulo</h4>
            <p className="">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu risus at nulla viverra ultrices sed imperdiet neque. Pellentesque pellentesque, mauris non pellentesque feugiat, sapien lacus tempor nulla, ac venenatis libero orci in velit. Donec lobortis quis purus eget placerat. Curabitur id neque fermentum, tristique orci eget, tempor enim. Vestibulum venenatis quam vel sapien mollis volutpat id vitae ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Interdum et malesuada fames ac ante ipsum primis in faucibus.

Aenean pellentesque sem metus, nec fringilla leo sodales et. Cras facilisis, turpis sit amet rhoncus sodales, dui risus sagittis libero, sit amet consequat quam ante eget quam. Cras viverra libero in magna varius faucibus. Aenean a augue sed neque condimentum ultrices eu vel est. Praesent pellentesque massa et placerat auctor. Sed aliquet urna a pharetra pretium. Sed cursus a risus vel varius. Donec tincidunt bibendum porta. Mauris vel ligula enim. Fusce nec elit ac diam posuere volutpat quis sed lorem. Aliquam erat volutpat. Vivamus non dapibus est.

Vestibulum pulvinar, dui sit amet porta consectetur, augue magna placerat mi, in suscipit nisl nibh eget risus. Ut vehicula tortor et dui lobortis vestibulum eget in dui. Cras rhoncus euismod nisl, sit amet congue nisi facilisis non. Ut finibus nisl et libero ornare placerat ut ut nunc. Vivamus vehicula eros quis ligula pellentesque fermentum. Phasellus a erat ultricies, convallis eros sed, suscipit nulla. Vivamus iaculis leo nec magna suscipit, in ornare mauris luctus. Aenean nec pretium felis. Etiam viverra efficitur purus, eget facilisis nisi sodales sed. Cras non fermentum diam, quis tempor enim.
            </p>
            </div>


            <div className="doubleSpace">
            <h4>Subtítulo</h4>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam tellus ut lectus lacinia aliquam. Proin tristique tristique nisl, vitae convallis massa euismod at. Donec nisi sapien, tempus ut fermentum pharetra, ullamcorper vel lorem. 
            </p>
            </div>

            <div className="doubleSpace">
            <h4>Subtítulo</h4>
            <p className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquam tellus ut lectus lacinia aliquam. Proin tristique tristique nisl, vitae convallis massa euismod at. Donec nisi sapien, tempus ut fermentum pharetra, ullamcorper vel lorem. 
            </p>
            </div>


            <div className="col-12 text-center my-auto order-lg-1 showcase-text">
                <button
                  className="btn btn-primary btn-black"
                  type="button">
                  Ir al registro
                </button>
              </div>




          </div>
        </div>
    </section>
  </div>
    );
  };
  