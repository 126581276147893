export const registraParticipante = async (userId, tempData , uripath, folio, imgURL) => {
    
    try {
      // Construye el objeto de datos a enviar al servidor
      const participanteData = {
        id: userId,
        nombre: tempData.nombre,
        apellidos: tempData.apellidos,
        email: tempData.email,
        edad: tempData.edad,
        genero: tempData.genero,
        pais: tempData.pais,
        calle: tempData.calle,
        numeroExterior: tempData.numeroExterior,
        numeroInterior: tempData.numeroInterior,
        cp: tempData.cp,
        municipio: tempData.municipio,
        estado: tempData.estado,
        telefono: tempData.telefono,
        identificacion: imgURL,
      };

      const uri = uripath+'save.php';

      // Realiza la solicitud POST al servidor PHP
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(participanteData),
      });

      // Verifica si la solicitud fue exitosa
      if (response.ok) {
        const data = await response.json();
        console.log('Respuesta del servidor:', data);

        // Verifica si el campo 'folio' está presente en la respuesta
        if (data.hasOwnProperty('folio')) {
            const folioGenerado = data.folio;
            folio(folioGenerado)
            
            console.log('Folio generado:', folioGenerado);
            return folioGenerado;
            // Puedes usar 'folioGenerado' como necesites en tu aplicación
        } else {
            console.warn('La respuesta no contiene el campo "folio".');
            return ""
        }
        
      } else {
        console.error('Error en la solicitud al servidor:', response.statusText);
        return ""
        // Manejo de errores si es necesario
      }
    } catch (error) {
      console.error('Error en la solicitud al servidor:', error.message);
      return ""
      // Manejo de errores si es necesario
    }
  };







  export const  registraImagenesLibre = async (userId, imagenes, uripath , infoUsuario , folio ) => {
    const uri = uripath+'saveImages.php'


     // Construye el objeto de datos a enviar al servidor
     const participanteData = {
      folio : folio,
      nombre: infoUsuario.nombre,
      apellidos: infoUsuario.apellidos,
      email: infoUsuario.email,
    };



      // Construir un array de objetos solo con la información necesaria
    const camposImagenes = imagenes.map((imagen) => ({
      user_id: userId,
      titulo: imagen.titulo,
      descripcion: imagen.descripcion,
      categoria: imagen.categoria,
      lugar: imagen.lugar,
      estado: imagen.estado,
    imagen_url: imagen.file
    }));

  // Enviar datos al servidor PHP
  try {
    const response = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({camposImagenes, participanteData} ),
    });

    const result = await response.json();
    console.log(result);

  }
  catch (error) {
    console.error('Error en la solicitud al servidor:', error.message);
    // Manejo de errores si es necesario
  }

}




export const  registraImagenesEnsayo = async (userId, informacionGeneral, imagenes, uripath , infoUsuario, folio ) => {
  const uri = uripath+'saveImagesE.php'


   // Construye el objeto de datos a enviar al servidor
   const participanteData = {
    folio : folio,
    nombre: infoUsuario.nombre,
    apellidos: infoUsuario.apellidos,
    email: infoUsuario.email,
  };


    // Construir un array de objetos solo con la información necesaria
  const camposImagenes = imagenes.map((imagen) => ({
    user_id: userId,   
    imagen_url: imagen.file
  }));

  const camposInfo = {
    user_id: userId,
    titulo: informacionGeneral.titulo,
    descripcion: informacionGeneral.descripcion,
    categoria: 'Ensayo',
    lugar: informacionGeneral.lugar,
    estado: informacionGeneral.estado,
  };


  console.table(camposImagenes)
  console.table(camposInfo)

  // Enviar datos al servidor PHP
  try {
    const response = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ camposInfo, camposImagenes, participanteData }),
    });

    const result = await response.json();
    console.log(result);

  }
  catch (error) {
    console.error('Error en la solicitud al servidor:', error.message);
    // Manejo de errores si es necesario
  }

}



