import React, { useEffect , useState  } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './NavBar.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const NavBar = ({ cambio }) => {

  const [showHome, setShowHome] = useState(true);
  const location = useLocation();

  useEffect(() => {
    cambio(); // Llama a la función 'cambio' cada vez que la ubicación (URL) cambie
    if(location.pathname === "/registro"){
      setShowHome(false)
    }else{
      setShowHome(true)
    }
  }, [location.pathname, cambio]);

  return (
    <Navbar  expand="lg" className={styles.navbar}>
      <Container className={styles.navbarContainer}>

        {showHome && (
          <Navbar.Brand >
              <div className="uppercase24L t-10">
                  <p><b className="stg">EL BANCO NACIONAL DE MÉXICO</b> A TRAVÉS DE<br />
                  FOMENTO CULTURAL CITIBANAMEX<br />
                  TE INVITA A PARTICIPAR EN LA 3<sup>ra</sup> EDICIÓN<br />
                  DEL CONCURSO FOTOGRÁFICO EN EL<br />
                  MARCO DE SUS 140 AÑOS
                  </p>
              </div>

          </Navbar.Brand>

        )}
        {!showHome && (

          <Navbar.Brand as={NavLink} to="/">
          <img
              // src="../demo/assets/big_logo.png"
              src="../assets/big_logo.png"
              width="250"
            
              className="d-inline-block align-top"
              alt="Logo"
            />

          </Navbar.Brand>
         )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto " style={{ backgroundColor: "#ccc", borderRadius: "5px" }}>
          {/* <Nav.Link as={NavLink} to="/registro" className={styles.navLink}>
            Registro
          </Nav.Link> */}

     

          <Nav.Link href="/downloads/Bases_premio_fotografico_El_Mexico_de_los_mexicanos_3ra_edicion.pdf" className={styles.navLink} download>
              Bases
          </Nav.Link>
          
          <Nav.Link as={NavLink} to="/#faq" className={styles.navLink}>
            FAQ
          </Nav.Link>
        </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
